<template>
  <div>
    <b-row>
      <!-- Search -->
      <b-col
        cols="12"
        class="mt-2 mb-4"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchValue"
            class="d-inline-block mr-1"
            placeholder="Filtrar..."
            @keyup="filtro($event, 'title', filterGlobal)"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <div
        v-for="item in congresses"
        :key="item.id"
        style="margin: 5px;"
      >
        <a
          :href=" `/todas-atividades/${item.id}/${item.path_event}`"
        >
          <b-card
            :title="item.title"
            :img-src="getImagePath(item.image)"
            img-alt="Image"
            img-top
            tag="article"
            md="4"
            style="max-width: 20rem;display:block;margin: 0 auto;"
          >
          </b-card>
        </a>
      </div>
      <b-modal
        id="evt-modal-notification"
        size="lg"
        title="Eventos para hoje"
        ok-only
        no-stacking
      >
        <div>
          Você tem alguns eventos para hoje:
          <ul
            id="notification"
            style="overflow-y: scroll;height:220px;"
          >
            <li
              v-for="e in evtList"
              :key="e.id"
            >
              {{ `${e.title} - ${e.start_time.substring(5, 0)}` }}

              <b-button
                variant="info"
                @click="goToUrl(e.congress.path_event, e.slug)"
              >
                Entrar ao vivo
              </b-button>
            </li>
          </ul>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import FormRatingBorderlessVue from '@/views/forms/form-element/form-rating/FormRatingBorderless.vue'
import CongressServices from './congressServices'
import store from '@/store'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      evtList: [],
      congresses: [],
      isSpecial: null,
      ordVerify: [],
      loading: true,
      path: ''
    }
  },
  computed: {
    speakerEvent() {
      return this.$store.state.congress.speakerEvents
    },
    order() {
      return this.$store.state.payment.order
    },
    ordersGn() {
      return this.$store.state.payment.ordersGn
    },
    user() {
      return this.$store.state.auth.sessionInfo.user
    },
    items() {
      return this.$store.state.payment.items
    },
  },
  async mounted() {
    this.path = process.env.VUE_APP_API_BASE
    await this.LoadCongressAll()
    await CongressServices().getOrderGn().then(res => {
      this.ActionSetOrdersGn(res.data.response.orders)
    })
    this.ActionGetEventBySpeaker()
    await this.ActionGetOrder()
    this.ordVerify = this.order
    this.congresses = this.$store.state.congress.congressAll
  },
  methods: {
    getImagePath(imageName) {
      const imagePath = imageName
        ? `${this.path}/storage/congress/image/${imageName}`
        : require('@/assets/default/cards/congressov2.png');

      return imagePath;
    },
    filtro(e, propriedadePesquisa, filterGlobal) {
      if (e.target.value.trim() !== '') {
        const lista = this.$store.state.congress.congressAll
        const result = filterGlobal(e, lista, propriedadePesquisa)
        this.congresses = result
      } else {
        this.congresses = this.$store.state.congress.congressAll
      }
    },
    ...mapActions('congress', [
      'ActionLoadCongressAll',
      'ActionGetEventBySpeaker'
    ]),
    LoadCongressAll() {
      try {
        this.ActionLoadCongressAll()
      } catch (err) {
        console.log(err)
      }
    },
    goToUrl(congress, slug) {
      window.location.href = `/congress/${congress}/${slug}`
    },
    ...mapActions('payment', [
      'ActionGetOrder',
      'ActionRemoveItem',
      'ActionAddItem',
      'ActionSetOrdersGn'
    ]),
    isOnCart(id) {
      return !this.items.some(e => e.congress_id === id)
    },
    async addToCart(obj) {
      await this.ActionAddItem(obj[0])
      localStorage.setItem('items', JSON.stringify(this.items))
    },
    async removeItem(item) {
      this.item = null
      // eslint-disable-next-line array-callback-return
      this.items.some(e => {
        if (e.id === item.id) {
          this.item = e
        }
      })
      await this.ActionRemoveItem(this.items.indexOf(this.item))
      localStorage.setItem('items', JSON.stringify(this.items))
    },
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    speakerEvent() {
      const q = new Date()
      const m = q.getMonth() + 1
      const d = q.getDate()
      const y = q.getFullYear()
      console.log('ok')
      const date = new Date(`${y}-${m}-${d} 00:00:00`)

      this.speakerEvent.forEach(element => {
        const evtDate = new Date(`${element.event.date} 00:00:00`)
        console.log(evtDate)
        console.log(date)

        if (evtDate.toString() === date.toString()) {
          this.evtList.push(element.event)
        }
      })
      console.log(this.evtList)
      if (this.evtList.length > 0) { this.$bvModal.show('evt-modal-notification') }
    },
    order() {
      this.ordVerify = this.order
    },
    ordVerify() {
      this.ordVerify = this.order
      if (
        this.user.role === 'ADMIN' ||
        this.user.role === 'MASTER' ||
        this.user.role === 'SPONSOR' ||
        this.user.role === 'SPEAKER'
      ) {
        this.isSpecial = true
      } else {
        this.isSpecial = false
      }
    },
    isSpecial() {
      this.loading = false
    },
  }
}
</script>

<style scoped>
#notification {
  list-style: none;
  margin: 10px 10px;
}

#notification li {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0;
}

#notification li button {
  margin-left: auto;
}

.row {
  display: flex !important;
}

@media only screen and (max-width: 500px) {
  .row {
    display: block !important;
  }
}
</style>
