import axios from '@axios'

export default function congressServices() {
  const getOrderGn = async (queryParams) => {
    const res = await axios.get('/order/paid/gn', queryParams)
    console.log(res)
    return res
  }

  const getDebaterEvent = async (queryParams) => {
    const res = await axios.get('/debater')
    return res
  }

  const getEventAll = async (queryParams) => {
    const res = await axios.get('/events/list', {
      params: queryParams,
    })
    console.log(res)
    return res
  }

  const getSpeakerBlocks = async (queryParams) => {
    const res = await axios.get('/speaker')
    return res
  }

  const getCordinatorEvent = async (queryParams) => {
    const res = await axios.get('/cordinator')
    return res
  }

  const getUserCongresses = async (queryParams) => {
    const res = await axios.get('/congresses')
    return res
  }

  const getScientificWork = async (queryParams) => {
    const res = await axios.get('/scientificwork/foruser')
    return res
  }

  const getDebaterCerficate = async (queryParams) => {
    const res = await axios.get('/certificate/debater', {
      params: queryParams,
    })

    return res
  }

  const getCordinatorCerficate = async (queryParams) => {
    const res = await axios.get('/certificate/cordinator', {
      params: queryParams,
    })

    return res
  }

  const getSpeakerCerficate = async (queryParams) => {
    const res = await axios.get('/certificate/speaker', {
      params: queryParams,
    })

    return res
  }

  const getUserCertificate = async (queryParams) => {
    const res = await axios.get('/certificate/user/congress', {
      params: queryParams,
    })

    return res
  }

  const getScientificWorkCerficate = async (queryParams) => {
    const res = await axios.get('/certificate/scientific-work', {
      params: queryParams,
    })

    return res
  }

  return {
    getOrderGn,
    getDebaterEvent,
    getDebaterCerficate,
    getCordinatorEvent,
    getCordinatorCerficate,
    getSpeakerBlocks,
    getSpeakerCerficate,
    getUserCongresses,
    getUserCertificate,
    getScientificWork,
    getScientificWorkCerficate,
    getEventAll,
  }
}
